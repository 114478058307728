.pre-heading {
    @apply text-stone-500 dark:text-stone-400 uppercase tracking-widest font-medium text-lg;
}

.heading {
    font-size: 7vw; //@todo clamp / min-max font-size
    @apply text-black dark:text-stone-200
}

.popover {
    &__title {
        @apply text-2xl font-semibold text-black dark:text-stone-200;
    }
    
    &__subtitle {
        @apply text-stone-400 font-medium text-lg;
    }
}

.text--main {
    @apply text-black dark:text-stone-200
};

.text--secondary {
    @apply text-stone-500;
}

.text--secondary {
    @apply text-stone-500;
}

.label {
    @apply uppercase tracking-widest font-medium text-xs;
}

ul.list {
    padding-left: .8rem;
    @apply list-disc list-outside;
}