.keyscontainer {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    max-width: calc((3 * (3rem + (2px * 2) + 1.5rem)) - 1.5rem);
    
    // .key {
    //     max-width: 3rem;
    // }
    
    // .key:nth-child(3n - 1) {
    //     justify-self: center;
    //     background-color: red;
    // }
}