.slide-routes {
    &, .item {
        @apply h-full max-h-screen overflow-scroll;
    }
}

hr {
    @apply border-t-2;
    @extend .border--themed; 
}

.border--themed {
    @apply border-stone-300 dark:border-stone-700;
}

.ratio-card {
    aspect-ratio: 1.6470588235;
}

.bg--themed {
    @apply bg-white dark:bg-stone-900;
}

.list__item {
    @extend .border--themed;
    @apply border-b border-stone-300 dark:border-stone-700 py-4
}

.container--responsive {
    @apply max-w-4xl;
}