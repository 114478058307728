* {
    &, &::after, &::before, &:checked {
        transition: all 0.25s ease;
    }
}

html {
    font-family: 'Sora', sans-serif;
}

html, body, #root, #app {
    @apply h-full;
    // overscroll-behavior-y: none;
}

body {
    min-height: calc(100% + env(safe-area-inset-top));
    // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    // padding: 
    //     calc(env(safe-area-inset-top, 20px) * -1)
    //     calc(env(safe-area-inset-right, 20px) * -1)
    //     calc(env(safe-area-inset-bottom, 20px) * -1)
    //     calc(env(safe-area-inset-left, 20px) * -1);
}